import { getInvoiceBalance } from '@/pages/dashboard/commerce/multiPayments/utils';
import { Tooltip, Typography } from '@mui/material';
import { differenceInDays, max, min } from 'date-fns';
import { get, startCase, toLower, uniq, upperFirst } from 'lodash-es';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import currencyFormat from '../../../../helpers/currencyFormat';

export function CalculateNumber( rows, type ) {
	const { t } = useTranslation();
	const numbersTotal = useMemo( () => rows?.length, [ rows ] );
	
	return (
		<Tooltip title={t( 'commerce:number-of-orders' )}>
			<Typography variant='body1'>
				{`${numbersTotal === 1 ? `${type}:` : `${type}s:`} ${numbersTotal}`}
			</Typography>
		</Tooltip>
	);
}

export function CalculateClients( rows, key, name? ) {
	const { t } = useTranslation();
	const computedValues = useMemo( () => {
		if ( key === 'Vendor' ) {
			const uniqueVendors = new Set(
				rows.map( ( row ) => row.values.id && row.values?.menu?.vendorName ).filter( Boolean ),
			);
			return { type: 'Vendor', count: uniqueVendors.size };
		} else {
			const uniqueClients = new Set(
				rows.map( ( row ) =>
					row.values?.id && Array.isArray( key )
						? get( row.values, key[ 0 ] ) || get( row.values, key?.[ 1 ] )
						: get( row.values, key ),
				).filter( Boolean ),
			);
			return { type: 'Client', count: uniqueClients.size };
		}
	}, [ rows, key ] );
	
	return (
		<Tooltip
			title={t( 'commerce:number-of-unique', {
				value: computedValues.type === 'Vendor'
					? t( 'common:vendors' )
					: toLower( name ),
			} )}>
			<Typography variant='body1'>
				{`${computedValues.count === 1
					? `${computedValues.type}:`
					: `${computedValues.type}s:`} ${computedValues.count}`}
			</Typography>
		</Tooltip>
	);
}

export function CalculateUnique( rows, key ) {
	const { t } = useTranslation();
	const items = useMemo( () => {
		const uniqueItems = uniq( rows.map( ( row ) => toLower( row.values?.[ key ] ) ).filter( Boolean ) );
		return uniqueItems.length;
	}, [ rows ] );
	
	return (
		<Tooltip title={t( 'commerce:number-of-unique', { value: key.split( '.' )[ 0 ] } )}>
			<Typography variant='body1'>{`${upperFirst( key.split( '.' )[ 0 ] )}:  ${items}`}</Typography>
		</Tooltip>
	);
}

export function CalculatePaymentType( rows ) {
	const { t } = useTranslation();
	const paymentTypes = useMemo( () => {
		const uniquePaymentTypes = new Set( rows.map( ( row ) =>
			startCase( toLower( row.values?.payments[ 0 ]?.type ) ) ).filter( Boolean ) );
		return uniquePaymentTypes.size;
	}, [ rows ] );
	
	return (
		<Tooltip title={t( 'commerce:number-unique-payment-types' )}>
			<Typography variant='body1'>{`${paymentTypes === 1 ? 'Type:' : 'Types:'} ${paymentTypes}`}</Typography>
		</Tooltip>
	);
}

export function CalculateDate( rows, dateType ) {
	const { t } = useTranslation();
	const days = useMemo( () => {
		const dates = rows.map( ( row ) => row.values?.[ dateType ] ).filter( Boolean );
		const maxDate = max( dates ),
		      minDate = min( dates );
		return differenceInDays( maxDate, minDate ) + 1 || 0;
	}, [ rows ] );
	
	return (
		<Tooltip title={t( 'commerce:number-of-unique-dates' )}>
			<Typography variant='body1'>{`${days === 1 ? 'Day:' : 'Days:'} ${days}`}</Typography>
		</Tooltip>
	);
}

export function CalculateTotal( rows, type?: string, isCurrency?: boolean ) {
	const { t } = useTranslation();
	const total = useMemo( () => rows.reduce( ( sum, row ) => ( row.values?.[ type ] || 0 ) + sum, 0 ), [ rows ] );
	
	return (
		<Tooltip title={t( 'commerce:total-amount' )}>
			<Typography variant='body1'>{isCurrency ? currencyFormat( +total ) : +total || 0}
			</Typography>
		</Tooltip>
	);
}

export function CalculateBuyAndSellQuantities( rows ) {
	const { t } = useTranslation();
	const total = useMemo( () => rows.reduce( ( sum, row ) => {
		if ( row.original.order && row.original.quantity > 0 ) {
			sum -= row.original.quantity;
		} else {
			sum += row.original.quantity || 0;
		}
		return sum;
		
	}, 0 ), [ rows ] );
	
	return (
		<Tooltip title={t( 'commerce:total-amount' )}>
			<Typography variant='body1'>{total || 0}
			</Typography>
		</Tooltip>
	);
}

export function CalculateBalance( rows, isCurrency?: boolean ) {
	const { t } = useTranslation();
	const total = useMemo( () => rows.reduce( ( sum, row ) => getInvoiceBalance( row.original ) + sum, 0 ), [ rows ] );
	
	return (
		<Tooltip title={t( 'commerce:total-amount' )}>
			<Typography variant='body1'>{isCurrency ? currencyFormat( +total ) : +total || 0}
			</Typography>
		</Tooltip>
	);
}

export function CalculateModifiedTotal( rows ) {
	const { t } = useTranslation();
	const total = useMemo( () => rows.reduce( ( sum, row ) => {
		sum += row.values.quantity * row.values.price;
		return +sum;
	}, 0 ), [ rows ] );
	
	return (
		<Tooltip title={t( 'commerce:total-amount' )}>
			<Typography variant='body1'>{currencyFormat( total )}</Typography>
		</Tooltip>
	);
}

export function CalculateStatsTotal( rows, stats, statsType ) {
	const { t } = useTranslation();
	const statsTotal = useMemo( () => rows.reduce( ( sum,
		row ) => +row.values?.[ statsType ].length + sum, 0 ), [ rows ] );
	
	return (
		<Tooltip title={t( 'commerce:total-stats-for-this-column' )}>
			<Typography variant='body1'>{statsTotal < 2
				? `${stats}: ${statsTotal}`
				: `${stats}s: ${statsTotal}`}
			</Typography>
		</Tooltip>
	);
}

export function percentageIncrease( a, b ) {
	if ( b !== 0 && a !== 0 ) {
		return Math.floor( ( b - a ) / a * 100 );
	} else {
		return 0;
	}
}

export function stayOpen( event, reason ) {
	if ( reason === 'backdropClick' || reason === 'escapeKeyDown' ) {
		throw new Error( 'Stay open on close' );
	}
}

export const calculateDistance = ( lat1, lng1, lat2, lng2 ) => {
	const dLat = ( lat2 - lat1 ) * Math.PI / 180;
	const dLon = ( lng2 - lng1 ) * Math.PI / 180;
	const a = 0.5 - Math.cos( dLat ) / 2 + Math.cos( lat2 * Math.PI / 180 ) * Math.cos( lat1 * Math.PI / 180 ) * ( 1 - Math.cos( dLon ) ) / 2;
	return Math.round( 6371 * 2 * Math.asin( Math.sqrt( a ) ) );
};

export const isBeforeTimeOfDay = ( date, hours, minutes ) => {
	const dateHours = date?.getHours();
	const dateMinutes = date?.getMinutes();
	if ( dateHours < hours ) {
		return true;
	}
	if ( dateHours === hours ) {
		if ( dateMinutes <= minutes ) {
			return true;
		}
	}
	return false;
};

export const getNextCustomNumber = ( customNumber ) => {
	if ( !customNumber ) return '';
	
	const match = customNumber.match( /(\d+)$/ );
	if ( !match ) return customNumber;
	
	const numberPart = match[ 0 ];
	const prefix = customNumber.slice( 0, -numberPart.length );
	
	const nextNumber = ( parseInt( numberPart, 10 ) + 1 ).toString();
	const paddedNextNumber = nextNumber.padStart( numberPart.length, '0' );
	
	return prefix + paddedNextNumber;
};

import Actions, { ActionProps } from '@/components/actions';
import DrawerHandle from '@/components/drawerHandle';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import PageWrapper, { PageWrapperProps } from '@/components/page/wrapper';
import { ArrowBack as ArrowBackIcon, Close as CloseIcon } from '@mui/icons-material';
import {
	Button,
	ButtonProps,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentProps,
	DialogProps,
	DialogTitle,
	Drawer,
	Grow,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	Slide,
	Stack,
	SwipeableDrawer,
	SwipeableDrawerProps,
	Theme,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, Fragment, ReactElement, ReactNode, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { menuDarkModeBoxShadow, popOverShadow } from '../theme/themes';
import { useModalControls } from './index';

export type ModalVariant = 'adaptive' | 'drawer' | 'modal' | 'fullPageDialog';

export type ResponsiveModalProps = {
	id?: string,
	open: boolean,
	onClose: ( event: object, reason?: 'backdropClick' | 'escapeKeyDown' ) => void,
	// type of modal to be displayed
	variant?: ModalVariant,
	fullPageModal?: boolean,
	fullPageBottomSheet?: boolean,
	children?: ReactNode
} & Partial<Omit<SwipeableDrawerProps & DialogProps, 'open' | 'onClose' | 'variant' | 'children'>>;

export type ResponsiveModalContainerProps = {
	onClose?: () => void,
	// type of modal to be displayed
	variant?: ModalVariant,
	title?: ReactNode,
	secondaryTitle?: string | ReactNode,
	// renders and called by save button if set
	onSave?: () => void | Promise<unknown>,
	saveButtonProps?: ButtonProps,
	cancelButtonProps?: ButtonProps,
	closeOnSave?: boolean,
	saveButtonText?: string | ReactNode,
	actionItems?: ActionProps[] | ReactElement | string | number,
	topActionItems?: ActionProps[],
	children?: ReactNode,
	loading?: boolean,
	hideClose?: boolean
} & Omit<DialogContentProps, 'title'>;

export function PopperWrapper( {
	title = 'Popper Title',
	children,
	...props
}: {
	title: string,
	children: ReactNode
} & PageWrapperProps ) {
	const { closeModal } = useModalControls();
	return (
		<PageWrapper
			hideBack
			primary={title}
			actions={(
				<IconButton onClick={() => closeModal()}>
					<CloseIcon/>
				</IconButton>
			)}
			{...props}>
			{children}
		</PageWrapper>
	);
}

export const DialogTransition = forwardRef( function Transition(
	props: TransitionProps & {
		children: ReactElement
	},
	ref: Ref<unknown>,
) {
	return (
		<Slide ref={ref} direction='up' {...props}>
			{props.children}
		</Slide>
	);
} );

export function SwipeableBottomSheet( {
	children,
	fullPageBottomSheet,
	...props
}: {
	fullPageBottomSheet?: boolean,
	children: ReactNode
} & any ) {
	const theme = useTheme();
	const isDarkMode = theme.palette.mode === 'dark';
	return (
		<SwipeableDrawer
			disableSwipeToOpen
			closeAfterTransition
			anchor='bottom'
			transitionDuration={350}
			sx={{ display: 'flex', zIndex: 'modal', justifyContent: 'center' }}
			{...props}
			PaperProps={{
				sx: {
					backgroundColor     : isDarkMode ? '#212223' : '#ffffff',
					maxHeight           : 'calc(100vh - env(safe-area-inset-top) - 50px)',
					minHeight           : '40%',
					height              : fullPageBottomSheet ? '100%' : 'auto',
					left                : 'auto',
					right               : 'auto',
					border              : 0,
					borderTopLeftRadius : 12,
					borderTopRightRadius: 12,
					width               : '100%',
					...props?.PaperProps?.sx,
				},
			}}
			onOpen={() => null}>
			{children}
		</SwipeableDrawer>
	);
}

export default function ResponsiveModal( {
	variant = 'adaptive',
	fullPageModal,
	fullPageBottomSheet,
	...props
}: ResponsiveModalProps ) {
	const theme = useTheme();
	const isDarkMode = theme.palette.mode === 'dark';
	const wide = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.up( 'sm' ) );
	
	if ( variant === 'fullPageDialog' && wide ) {
		return (
			<Dialog
				fullWidth
				fullScreen
				disablePortal
				closeAfterTransition
				TransitionComponent={DialogTransition}
				{...props}
				sx={{
					...props.sx,
					'.MuiDialog-container': {
						bgcolor: isDarkMode && 'rgba(8,8,9,0.62)',
					},
					'.MuiDialog-paper': {
						...props.sx?.[ '.MuiDialog-paper' ],
						borderRadius: '0 !important',
						border      : 0,
						boxShadow   : 'none',
						height      : '100%',
					},
				}}
			/>
		);
	}
	
	if ( variant === 'modal' || variant === 'adaptive' && wide ) {
		return (
			<Dialog
				fullWidth
				disablePortal
				closeAfterTransition
				maxWidth='md'
				TransitionComponent={Grow}
				{...props}
				sx={{
					...props.sx,
					'.MuiDialog-container': {
						bgcolor: isDarkMode && 'rgba(8,8,9,0.62)',
					},
					'.MuiDialog-paper': {
						borderRadius: 4,
						...props.sx?.[ '.MuiDialog-paper' ],
						border      : 'none',
						boxShadow   : !isDarkMode
							? theme.shadows[ popOverShadow as any ]
							: menuDarkModeBoxShadow,
						ml    : 'env(safe-area-inset-left)',
						mr    : 'env(safe-area-inset-right)',
						// mt          : 'env(safe-area-inset-top)',
						// mb          : 'env(safe-area-inset-bottom)',
						height: fullPageModal ? '100%' : undefined,
					},
				}}
			/>
		);
	}
	
	if ( variant === 'drawer' && wide ) {
		return (
			<Drawer
				transitionDuration={350}
				anchor='right'
				{...props}
			/>
		);
	}
	
	return (
		<SwipeableBottomSheet
			fullPageBottomSheet={fullPageBottomSheet}
			{...props}
		/>
	);
	
}

export function ResponsiveModalContainer( {
	onClose,
	variant,
	title,
	secondaryTitle,
	onSave,
	closeOnSave = true,
	saveButtonText,
	loading,
	cancelButtonProps,
	saveButtonProps,
	actionItems,
	topActionItems,
	hideClose,
	...props
}: ResponsiveModalContainerProps ) {
	const { t } = useTranslation();
	const wide = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.up( 'sm' ) );
	const { closeModal, modalInfo } = useModalControls();
	
	saveButtonText = saveButtonText || t( 'common:save' );
	variant = variant ?? modalInfo?.modalProps?.variant ?? 'adaptive';
	onClose = onClose ?? closeModal;
	
	if ( variant === 'modal' || variant === 'adaptive' && wide ) {
		return (
			<Fragment>
				{title && (
					<DialogTitle>
						<Stack direction='row'>
							<ListItemText
								sx={{ p: 0 }}
								primary={title}
								primaryTypographyProps={{ variant: 'h3' }}
								secondary={secondaryTitle}
							/>
							{topActionItems && (
								<Actions
									separated
									items={topActionItems}
								/>
							)}
						</Stack>
					</DialogTitle>
				)}
				<DialogContent dividers {...props}/>
				<DialogActions sx={{ p: 2 }}>
					<Stack direction='row' alignItems='center' spacing={1}>
						{!hideClose && (
							<AsyncLoadingButton
								variant='outlined'
								{...cancelButtonProps}
								onClick={onClose}>
								{onSave ? t( 'common:cancel' ) : t( 'common:close' )}
							</AsyncLoadingButton>
						)}
						{Array.isArray( actionItems )
							? <Actions separated items={actionItems}/>
							: actionItems}
						{onSave ? (
							<AsyncLoadingButton
								variant='contained'
								color='primary'
								{...saveButtonProps}
								loading={loading}
								onClick={async () => {
									await onSave();
									closeOnSave && onClose();
								}}>
								{saveButtonText}
							</AsyncLoadingButton>
						) : undefined}
					</Stack>
				</DialogActions>
			</Fragment>
		);
	} else {
		return (
			<Fragment>
				<DialogTitle sx={{ p: 0, borderBottom: 1, borderColor: 'divider' }}>
					<DrawerHandle/>
					<Stack direction='row'>
						<ListItem>
							<ListItemIcon sx={{ minWidth: 20, alignSelf: 'start', mt: .5 }}>
								<IconButton edge='start' onClick={onClose}>
									<ArrowBackIcon/>
								</IconButton>
							</ListItemIcon>
							<ListItemText
								sx={{ p: 0, flexGrow: 1 }}
								primary={title}
								primaryTypographyProps={{ variant: 'h3' }}
								secondaryTypographyProps={{ width: '80%' }}
								secondary={secondaryTitle}
							/>
						</ListItem>
						{topActionItems && (
							<Actions
								separated
								items={topActionItems}
							/>
						)}
					</Stack>
				</DialogTitle>
				<DialogContent
					sx={{ p: 1, overflowX: 'hidden' }}
					onTouchStart={( e ) => e.stopPropagation()}
					{...props}
				/>
				{( actionItems || onSave ) && (
					<DialogActions sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
						{Array.isArray( actionItems )
							? <Actions separated items={actionItems}/>
							: actionItems}
						{onSave ? (
							<AsyncLoadingButton
								variant='contained'
								color='primary'
								sx={{ width: '100%' }}
								{...saveButtonProps}
								loading={loading}
								onClick={async () => {
									await onSave();
									closeOnSave && onClose();
								}}>
								{saveButtonText}
							</AsyncLoadingButton>
						) : undefined}
					</DialogActions>
				)}
			</Fragment>
		);
	}
}

import { getBrowserTimezone } from '@/utils/timezone';
import * as dateFns from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';
import { isArray } from 'lodash-es';

type DateFnsMethods = typeof dateFns & typeof dateFnsTz;
export const isDateValid = ( date: any ): date is Date | number => date !== null && date instanceof Date && dateFns.isValid( date );
const parseDateFromString = ( dateString: string ): Date | '' => {
	try {
		const parsedDate = dateFns.parseISO( dateString );
		return isDateValid( parsedDate ) ? parsedDate : '';
	} catch {
		return '';
	}
};

// Proxy handler for safeDateFns
const safeDateFnsHandler: ProxyHandler<DateFnsMethods> = {
	get: ( target, prop: keyof DateFnsMethods ) => {
		const fn: any = target[ prop ];
		if ( typeof fn === 'function' ) {
			return ( ...args: any[] ) => {
				if ( typeof args[ 0 ] === 'string' ) {
					args[ 0 ] = parseDateFromString( args[ 0 ] );
				}
				
				if ( isArray( args[ 0 ] ) && !args[ 0 ].every( ( date ) => isDateValid( date ) ) || !isArray( args[ 0 ] ) && !isDateValid( args[ 0 ] ) ) {
					// For formatting functions, return an empty string for invalid dates
					if ( prop.startsWith( 'format' ) ) {
						return '';
					} else {
						// For other functions, return undefined
						return undefined;
					}
				}
				
				return fn( ...args );
			};
		}
		return fn;
	},
};

// Combine date-fns and date-fns-tz libraries into one object
const combinedDateFns: DateFnsMethods = { ...dateFns, ...dateFnsTz };

// Create the proxy
const safeDateFns = new Proxy( combinedDateFns, safeDateFnsHandler );

export const safeFormatInTimeZone = (
	date: Date | undefined | null,
	format: string,
	timeZone?: string,
) => safeDateFns.formatInTimeZone( date as Date, timeZone || getBrowserTimezone(), format );

export const safeConvertToZonedTime = (
	date: Date | string,
	timezone?: string,
) => safeDateFns.utcToZonedTime(
	date && typeof date === 'string' ? parseDateFromString( date ) : date,
	timezone || getBrowserTimezone(),
);

export default safeDateFns;

import { Device } from '@capacitor/device';
import type { AppBarProps } from '@mui/material';
import { AppBar, Theme, useMediaQuery, useScrollTrigger, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import type { MutableRefObject, ReactNode } from 'react';
import { useRef } from 'react';
import useGetDeviceInfo from '../hooks/useGetDeviceInfo';

export const blurryBackground = ( dontBlur?: boolean ) => {
	if ( dontBlur ) return;
	return {
		backdropFilter : 'saturate(180%) blur(20px)',
		backgroundColor: ( { palette } ) => palette.mode === 'dark'
			? 'rgba(29,29,31,0.72)'
			: '#fefefe77',
	};
};
export default function ElevationScroll( {
	targetRef,
	showBorder,
	...props
}: {
	children?: ReactNode,
	showBorder?: boolean,
	targetRef?: MutableRefObject<HTMLDivElement | null>
} & AppBarProps ) {
	const ref = useRef<HTMLDivElement>( null );
	const theme = useTheme();
	const { isCloverDevice } = useGetDeviceInfo();
	
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	
	const trigger = useScrollTrigger( {
		disableHysteresis: true,
		threshold        : 0,
		target           : targetRef?.current || window,
	} );
	
	const triggerForPadding = useScrollTrigger( {
		disableHysteresis: true,
		threshold        : 100,
		target           : window,
	} );
	
	const { data } = useQuery( [ 'device' ], () => Device.getInfo() );
	
	return (
		<AppBar
			ref={ref}
			{...props}
			color={data?.platform === undefined ? 'transparent' : undefined}
			sx={{
				'border'        : showBorder ? '0px 1px 1px 1px' : 0,
				'borderColor'   : theme.palette.divider + ' !important',
				'color'         : ( { palette } ) => palette.text.primary,
				'zIndex'        : isCloverDevice ? 999999 : undefined,
				'backdropFilter': data?.platform !== undefined && trigger && !isCloverDevice
					? 'saturate(180%) blur(20px)'
					: 'none',
				'backgroundColor': ( { palette } ) => trigger
					? palette.mode === 'dark'
						? 'rgba(29,29,31,0.72)'
						: '#fefefe77'
					: 'transparent',
				'transition': 'all 0.4s ease-in-out',
				'boxShadow' : 'none',
				':after'    : !isMobile ? {
					content   : '""',
					position  : !isCloverDevice ? 'absolute' : 'unset',
					left      : 0,
					width     : '100%',
					transition: 'all 0.3s ease-in-out',
					height    : '1px',
					bgcolor   : 'divider',
					display   : showBorder ? 'none' : 'inherit',
					top       : !isCloverDevice ? ref.current?.offsetHeight : 'auto',
					opacity   : trigger ? 1 : 0,
				} : undefined,
				'borderBottom': isMobile && triggerForPadding ? 1 : 0,
				'pt'          : isMobile && triggerForPadding ? 'env(safe-area-inset-top)' : 'unset',
				...props.sx,
			}}
			elevation={trigger ? 4 : 0}
			position={!isCloverDevice ? 'sticky' : 'static'}
		/>
	);
}

import currencyFormat from '@/helpers/currencyFormat';
import { safeFormatInTimeZone } from '@/helpers/safeFormat';
import { getHATotalFromPayments } from '@/pages/dashboard/commerce/components/sharedActionUtils';
import { Client, Company, Order, Staff } from '@/types/schema';
import postCloverMeteredBilling from '@/utils/api/postCloverMeteredBilling';
import { gql } from '@apollo/client';
import axios from 'axios';
import { isEmpty, toLower } from 'lodash-es';

export const meteredBillingAndTracking = async (
	paymentData,
	orderId: string | undefined,
	gatewayId: string | undefined,
	staffId: string | undefined,
	clientId: string | undefined,
) => {
	if ( paymentData ) {
		try {
			await postCloverMeteredBilling( {
				orderId,
				gatewayId,
				eventType: 'Account Payment',
				key      : 'invoicePayment',
				staffId,
				clientId,
			} );
		} catch {
		}
	}
};

export const sendMessagesAfterPayment = async ( staff: Staff,
	company: Company,
	orderClient: Client,
	amount: number ) => {
	try {
		if ( company.cell && !staff ) {
			await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/message`, {
				company: company.id,
				number : company.cell,
				message: `Hi ${company?.name || company?.contact || ''}\n`
					+ `You just got paid by ${orderClient?.name || orderClient?.contact || 'a client'}\n`
					+ `in amount of ${currencyFormat( amount )}`,
			} );
		}
	} catch {
	}
	try {
		const client = orderClient;
		if ( client?.cell && !staff ) {
			await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/message`, {
				company: company.id,
				number : client?.cell,
				message: `Hi ${client.name || client.contact || ''}\n`
					+ `You’ve just made a payment to ${company?.name || company?.contact || 'a company'} for ${currencyFormat( amount )}.
Your payment will be complete once the status updates to PAID.
If the status remains OPEN, please follow up with ${company?.name || company?.contact || 'the company'} for confirmation.`,
			} );
		}
	} catch {
	}
};

export const getHAInvoiceLineItems = ( invoices: Order[] ) => invoices.map( ( invoice, index ) => {
	const desc = safeFormatInTimeZone( invoice.serviceDate || invoice.updatedAt || new Date(), 'PP' );
	
	let price: number = 0;
	if ( invoice.status !== 'PAID' && !isEmpty( invoice.payments ) ) {
		const paymentAmount = getHATotalFromPayments( invoice );
		price += paymentAmount.paidAmount - paymentAmount.refundedAmount;
	} else {
		if ( invoice.status === 'PAID' ) {
			price = isEmpty( invoice.payments ) ? invoice.paidTotal || 0 : 0;
		} else {
			price += invoice.grandTotal - ( invoice.paidTotal || 0 );
		}
	}
	
	return {
		id  : undefined,
		name: `#${invoice.externalId || invoice.number} ${invoice.metadata?.customNumber
			? `(${invoice.metadata?.customNumber})`
			: ''}`,
		tax        : 0,
		sequence   : index,
		price,
		quantity   : 1,
		unit       : 'Order',
		description: `${desc} ${invoice.po ? `PO: ${invoice.po}` : ''} ${invoice.notes ? `Note: ${invoice.notes}` : ''}`,
	};
	
} );

export const getInvoiceBalance = ( invoice: Order ) => {
	const payments = invoice.payments || [];
	let balance: number = 0;
	
	if ( invoice.status === 'ACCOUNT' ) {
		if ( !isEmpty( payments ) ) {
			balance = getPaymentsByInvoissTender( payments );
		} else {
			balance = invoice.grandTotal || 0;
		}
		
	} else if ( invoice.status === 'CANCELLED' || invoice.status === 'VOIDED' || invoice.status === 'PAID' ) {
		balance = 0;
	} else {
		balance = ( invoice.grandTotal || 0 ) - ( invoice.paidTotal || 0 );
	}
	
	return balance || 0;
};

export const getPaymentsByInvoissTender = ( payments ) => {
	if ( isEmpty( payments ) ) return 0;
	return payments.reduce( ( balance, payment ) => {
		if ( payment.status === 'PAID' && [ 'haps', 'invoiss' ].includes( toLower( payment.type ) ) ) {
			balance += payment.amount + ( payment.tip || 0 ) || 0;
		}
		if ( payment.status === 'REFUNDED' || payment.status === 'PARTIALLY_REFUNDED' ) {
			balance -= payment.refundedAmount || payment.amount || 0;
		}
		
		return balance;
		
	}, 0 );
};

export const clientPaymentsReadGQL = gql`query ClientPaymentsRead_fccc($options: FilterOptions) {
	clientPaymentsRead(options: $options) {
		items {
			id
			type
			amount
		}
		count
	}
}`;

import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import type { ButtonProps } from '@mui/material';
import { Breadcrumbs, Button } from '@mui/material';
import { startCase } from 'lodash-es';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import PageLinkComponent from './linkComponent';

export interface PageBackProps extends ButtonProps {
	confirm?: boolean,
	useBreadCrumbs?: boolean,
	defaultBack?: boolean,
	name?: string,
	href?: string
}

export default function PageBack( {
	sx,
	confirm,
	onClick,
	useBreadCrumbs,
	defaultBack = true,
	name,
	href,
	...props
}: PageBackProps ) {
	const router = useRouter();
	const confirmDialog = useConfirmDialog();
	
	const routes = useMemo( () => {
		let href = '';
		const names = router.asPath.split( '/' );
		const paths = router.route.split( '/' );
		
		const reduced = paths.reduce( ( prevValue, currValue, currIndex ) => {
			
			if ( !currValue ) {
				prevValue.push( { name: 'Home Page', href: '/' } );
				return prevValue;
			}
			if ( currIndex === paths.length - 1 ) return prevValue;
			href += `/${names[ currIndex ]}`;
			if ( currValue === 'dashboard' ) return prevValue;
			if ( currValue === '[id]' ) currValue = 'View';
			
			prevValue.push( { name: startCase( currValue ), href } );
			return prevValue;
		}, [] as { name: string, href: string }[] );
		
		reduced.shift();
		return reduced.filter( ( { href } ) => href !== '/dashboard/commerce' && href !== '/dashboard/management' && href !== '/dashboard/settings' );
	}, [ router.asPath ] );
	
	const clickListener = ( href ) => async ( e ) => {
		if ( confirm ) {
			const value = await confirmDialog( {
				title  : 'Are you sure you want to leave?',
				message: 'You would lose your current changes.',
			} );
			if ( !value ) throw new Error( 'cancel' );
			await router.push( href );
		}
		
		await onClick?.( e );
	};
	
	if ( defaultBack && ( name || href ) ) {
		console.warn( 'Both defaultBack and (name or href) are provided. defaultBack will take precedence.' );
	}
	
	if ( defaultBack || name && href ) {
		const { href: hrefFromUrl } = routes[ routes.length - 1 ] || {};
		if ( !hrefFromUrl ) return null;
		return (
			<Button
				startIcon={<ArrowBackIcon/>}
				variant='text'
				color='primary'
				sx={{
					mt           : 1,
					pl           : 0,
					textTransform: 'none',
					boxShadow    : 'none !important',
					fontSize     : 16,
					...sx,
				}}
				component={!defaultBack && href ? PageLinkComponent : undefined as any}
				href={!defaultBack && href ? href : undefined}
				onClick={defaultBack ? () => history.back() : onClick}
				{...props}>
				{defaultBack ? 'Back' : name}
			</Button>
		);
	}
	
	if ( !useBreadCrumbs ) {
		const { name, href } = routes[ routes.length - 1 ] || {};
		if ( !href ) return null;
		return (
			<Button
				startIcon={<ArrowBackIcon/>}
				variant='text'
				color='primary'
				sx={{
					mt           : 1,
					pl           : 0,
					textTransform: 'none',
					boxShadow    : 'none !important',
					fontSize     : 16,
					...sx,
				}}
				component={confirm ? undefined : PageLinkComponent}
				href={confirm ? undefined : href}
				onClick={clickListener( href )}>
				{name}
			</Button>
		);
	} else {
		return (
			<Breadcrumbs sx={{ mt: 1 }}>
				{routes.map( ( { href, name }, index ) => (
					<Button
						key={index}
						color='primary'
						variant='text'
						component={PageLinkComponent}
						startIcon={index === 0 && <ArrowBackIcon/>}
						href={href}
						onClick={clickListener( href )}>
						{name}
					</Button>
				) )}
			</Breadcrumbs>
		);
	}
}

import type { DependencyList } from 'react';
import { useEffect } from 'react';

export default function useEventEffect(
	// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
	event: { on: Function, off: Function } | { addListener: Function, removeListener: Function } | {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
		addEventListener: Function,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
		removeEventListener: Function
	},
	eventName: string | symbol | keyof WindowEventMap,
	listener: ( ...args: any[] ) => void,
	deps?: DependencyList,
	callOnce?: boolean,
) {
	useEffect( () => {
		// @ts-ignore
		const add = event.on || event.addListener || event.addEventListener;
		// @ts-ignore
		const remove = event.off || event.removeListener || event.removeEventListener;
		
		if ( callOnce ) listener();
		add.bind( event )( eventName, listener );
		return () => {
			remove.bind( event )( eventName, listener );
		};
	}, deps );
}

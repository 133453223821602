import useUserInfo from '@/providers/auth/useUserInfo';
import { KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';
import { Fab, useScrollTrigger, Zoom } from '@mui/material';
import { useRouter } from 'next/router';
import useGetDeviceInfo from '../hooks/useGetDeviceInfo';

export default function ScrollTop() {
	const { staff } = useUserInfo() ?? {};
	const { isCloverDevice } = useGetDeviceInfo();
	const router = useRouter();
	const trigger = useScrollTrigger( { disableHysteresis: true } );
	
	const quickActions = staff?.actionsData?.quickActions;
	
	if ( isCloverDevice ) return null;
	
	return (
		<Zoom in={trigger}>
			<Fab
				color='primary'
				size='medium'
				sx={{
					boxShadow: 5,
					position : 'fixed',
					zIndex   : 1200,
					bottom   : 'calc(env(safe-area-inset-bottom) + 24px)',
					right    : `calc(env(safe-area-inset-right) + ${Boolean( quickActions?.length && router.asPath.split( '/' )[ 1 ] !== 'client' )
						? '80px'
						: '24px'})`,
					mb: { xs: 8, sm: 0 },
				}}
				onClick={() => window.scrollTo( { top: 0, behavior: 'smooth' } )}>
				<KeyboardArrowUpIcon/>
			</Fab>
		</Zoom>
	);
}
